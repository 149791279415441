import {
	WEIGHT_GAUGE_100,
	WEIGHT_GAUGE_100_IMG,
	WEIGHT_GAUGE_180,
	WEIGHT_GAUGE_180_IMG,
	WEIGHT_GAUGE_210,
	WEIGHT_GAUGE_210_IMG,
	WEIGHT_GAUGE_240,
	WEIGHT_GAUGE_240_IMG,
	WEIGHT_GAUGE_300,
	WEIGHT_GAUGE_300_IMG,
	WEIGHT_GAUGE_DEFAULT_MAX_VALUE,
	WEIGHT_GAUGE_GREEN_LINE_IMG,
	WEIGHT_GAUGE_MAX_ROTATION,
	WEIGHT_GAUGE_RED_LINE_IMG,
} from 'helpers/const/weight-gauge';
import styled from 'styled-components';

type WrapperProps = {
	$maxvalue: number;
	$value: number;
};
const Wrapper = styled.div<WrapperProps>`
	/* 225 × 222  */
	width: 225px;
	height: 222px;
	position: relative;
	img {
		width: 100%;
		height: 100%;
	}
	.line {
		position: absolute;
		left: 0;
		top: ${(props: any) => (props.$maxvalue === 240 ? '20px' : '20px')};
		right: 0;
		bottom: 0;
		z-index: 9;
		transform: ${(props: any) => `rotate(${props.$value}deg)`};
	}
	.value {
		position: absolute;
		color: white;
		left: 65px;
		top: ${(props: any) => (props.$maxvalue === 240 ? '130px' : '130px')};
		width: 96px;
		font-size: 13px;
		font-weight: 600;
		text-align: right;
		padding: 0 4px;
	}
`;

/**
 * An object that maps weight gauge maximum values to their corresponding image sources.
 *
 * @property {string} WEIGHT_GAUGE_100 - The image source for a weight gauge with a maximum value of 100.
 * @property {string} WEIGHT_GAUGE_210 - The image source for a weight gauge with a maximum value of 210.
 * @property {string} WEIGHT_GAUGE_240 - The image source for a weight gauge with a maximum value of 240.
 * @property {string} WEIGHT_GAUGE_300 - The image source for a weight gauge with a maximum value of 300.
 */
const WeightImage = {
	[WEIGHT_GAUGE_100]: WEIGHT_GAUGE_100_IMG,
	[WEIGHT_GAUGE_180]: WEIGHT_GAUGE_180_IMG,
	[WEIGHT_GAUGE_210]: WEIGHT_GAUGE_210_IMG,
	[WEIGHT_GAUGE_240]: WEIGHT_GAUGE_240_IMG,
	[WEIGHT_GAUGE_300]: WEIGHT_GAUGE_300_IMG,
};

/**
 * A React component that renders a weight meter gauge with a customizable maximum value.
 *
 * @param {number} value - The current value to display on the weight meter.
 * @param {number} [maxValue=WEIGHT_GAUGE_DEFAULT_MAX_VALUE] - The maximum value for the weight meter. Defaults to `WEIGHT_GAUGE_DEFAULT_MAX_VALUE`.
 */
function WeightMeter({
	value,
	maxValue = WEIGHT_GAUGE_DEFAULT_MAX_VALUE,
}: {
	value: number;
	maxValue: number;
}) {
	const defaultValue: number =
		typeof value === 'number' && !Number.isNaN(value) ? value : 0;
	let rotation = (defaultValue / maxValue) * WEIGHT_GAUGE_MAX_ROTATION;
	// Convert negative values to 0.
	if (rotation < 0) rotation = 0;
	// if the rotation value should exceel to the max rotation, set the max rotation to the rotation
	if (rotation > WEIGHT_GAUGE_MAX_ROTATION)
		rotation = WEIGHT_GAUGE_MAX_ROTATION - 9; // Rotate the line to gauge's last line.

	const weightImg = WeightImage[maxValue] || WeightImage[WEIGHT_GAUGE_300];

	// If true then it should show red line image, Otherwise it should show green line image (The original one.)
	const lineImage =
		value >= maxValue ? WEIGHT_GAUGE_RED_LINE_IMG : WEIGHT_GAUGE_GREEN_LINE_IMG;

	return (
		<Wrapper $maxvalue={maxValue} $value={rotation || 0}>
			<img
				src={weightImg}
				alt={`Weight gauge for ${maxValue} lbs`}
				title="weight-gauge"
			/>
			<div className="line" style={{ transition: 'all 1s ease-in-out' }}>
				<img src={lineImage} alt="line" title="line" />
			</div>
			<span className="value">{defaultValue}</span>
		</Wrapper>
	);
}

export default WeightMeter;
