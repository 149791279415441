import styled, { css } from 'styled-components';

export const AccordionTitle = styled.div`
	.accordion-button {
		color: black;
		background: #E3E3E3;
	}
	.accordion-button.collapsed::after {
		color: black;
		background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
	}
	.accordion-button:not(.collapsed)::after {
		color: black;
		transform: scaleY(-1) !important;
		background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
	}
`;

export const EquimentSelector = styled.div`
	.equipment.accordion {
		display: flex;
		flex-wrap: wrap;
		.card {
			cursor: pointer;
			margin: 0.25rem 0.1rem;
		}
		.card.selected {
			background:#198754;
			.card-header h6{
				color:white;
			}
		}
	}
`;

export const CatCount = styled.sub`
	position: absolute;
	top: 2rem;
	right: 3rem;
	display: none;
`;

export const Yard = styled.div`
	div:hover {
		background: #F0F0F0;
		// font-size: 0.9em;
	}
	div {
		padding: 0.5rem 1rem;
		background: #F3F3F3;
		cursor: pointer;
	}
`;

export const SelectAllRegion = styled.div`
	// width: 10%;
	position: absolute;
	right: 0px;
	text-align: center;
	top: 0px;
	font-size: 0.85rem;
	// height: 3.7rem;
	// height: auto;
	box-sizing: border-box;
	// border: black solid 0.1rem;
	// line-height: 3.7rem;
	cursor: pointer;
	:hover {
		background: #f3f3f3;
		cursor: pointer;
		font-weight: 500;
	}
`;

export const ResetPromptSpan = styled.span`
	line-height: 2.5;
	.rst_cancel {
		border: #E04321 solid 1px;
		background: #00000000;
	}
	.rst_cancel:hover {
		color: white;
		background: #E04321;
	}
	.rst_continue {
		background: #00834d;
		border: #00834d solid 1px;
		color: white;
	}
	.rst_continue:hover {
		background: #00a859;
	}
`;
export const ResetPromptBtn = styled.button`
	font-size: 10pt;
	line-height: 1;
	padding: 0.5rem 1rem;
	border-radius: 0.5rem;
	border: none;
	float: right;
	transition: all 0.2s ease-in-out;
`;
export const ResetBtn = styled.button`
	border-radius: 0.5rem;
	border: solid 1px #E04321;
	background: white;
    border-radius: 12px;
    color: black;
    padding: 0.75rem 1.75rem;
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
`;

export const Wrapper = styled.div`
	.reset_btn:hover {
		color: white;
		background: #E04321;
	}
	label {
		cursor: pointer;
	}
	h1 {
		font-family: 'Poppins';
		font-style: normal;
		font-weight: 700;
		font-size: 1.75rem;
		margin-bottom: 1rem;
	}
	h6 {
		font-weight: 500;
		font-size: 0.875rem;
	}
	.content {
		background: #ffffff;
		box-shadow: 0px 9px 36px rgba(0, 0, 0, 0.08);
		border-radius: 0.75rem;
		padding: 1rem;

		h2 {
			font-family: 'Poppins';
			font-style: normal;
			font-weight: 400;
			font-size: 1rem;
		}
	}
	.accordion-item {
		background: transparent;
		border: none;
	}
	.accordion > div > .px-1 {
		display: flex;
		height: 2rem;
		margin-bottom: 0.5rem;
	}
	.accordion-button {
		background-color: ${({ theme }) => theme.colors.tableHeader};
		color: white;
	}
	.accordion-button h3 {
		font-size:1rem;
	}
	.accordion-button:after {
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
	}
	.search-input {
		max-width: 260px;
		border-radius: 8px;
		background: ${({ theme }) => theme.colors.backgroundLightGray};
		height: 44px;
		font-size: 0.813rem;
		font-weight: 500;
		padding-left: 2.5rem;
	}
	.search-icon {
		position: absolute;
		margin-left: 1rem;
		margin-top: 0.75rem;
	}
	.project-details {
		margin: 0rem;
		margin-left: 0.5rem;

		@media (max-width: 500px) {
			margin-left: 0rem;
		}
	}

	.project,
	.eq-option {
		display: flex;
		background: #f3f3f3;
		color: black;
		padding: 1rem;
		h5 {
			font-weight: 600;
			font-size: 1rem;
		}
		h6 {
			color: #131313;
			font-weight: 400;
			font-size: 1rem;
		}
	}
	.check {
		input {
			&:checked {
				background-color: ${({ theme }) => theme.colors.primary};
				border-color: transparent;
			}
		}
	}
	.eq-option {
		background-color: transparent;
		h5 {
			font-weight: 500;
			font-size: 1rem;
		}
	}
	.card:nth-child(odd) {
		background-color: #f6f6f6;
	}
	.equipment {
		.accordion-item {
			padding: 0.5rem 0rem;
			border-bottom: 1px solid #dddddd;
			min-height: 3.5rem;
    		padding-top: 0.75rem;
		}
		h6 {
			margin: 0rem;
			max-width: 8rem;
			@media (max-width: 1400px) {
				max-width:22rem;
				position: relative;
				top:-1rem;
			}
			@media (min-width: 1480px) {
				max-width:11rem; 
			}
			@media (min-width: 1700px) {
				max-width:14rem; 
			}
			@media (min-width: 1850px) {
				max-width:22rem; 
			}
		}
		label {
			color: black;
			font-size: small;
   			// font-weight: 600;
		}
		.equipment-input {
			input {
				margin-top: 0.125rem;
			}
		}
		.accordion-button {
			background-color: transparent;
			color: #00834d;
			padding: 0.5rem 1rem;
		}
		.accordion-header,
		.accordion-body {
			
		}
		.card-header {
			display: flex;
			background-color: transparent;
			border: none !important;
			box-shadow: none;
		}
		.card {
			border: none;
		}
		.form-check-input {
			background-color: #f3f3f3;
			border: 1px solid #e1e1e1 !important;
		}
		.form-check-input:hover {
			border: 1px solid #bebebe !important;
		}
	}
	.toggleLabel {
		padding-left: 2px;
		padding-top: 2px;
	}
	.alert-list-view {
		overflow: auto;
		max-height: 62vh;
	}
	.selectallalerts {
		display: none;
		background: #0000;
		color: white;
		position: absolute;
		right: 1.5rem;
		top: 0rem;
	}
	.selectallalerts {
		.check {
			position: relative;
			bottom: 0rem;
			appearance: auto;
			accent-color: #098350;
		}
		@media (max-width: 1400px) {
			left: 1rem;
			top: 1rem;
		}
	}
	.selectAllEquipment {
		border-radius: 50%;
		vertical-align: middle;
		border: 1px solid #ddd;
		appearance: none;
		-webkit-appearance: none;
		outline: none;
		cursor: pointer;
		min-width: 1em;
		margin-right: 0.25em;
	}
	.selectAllEquipment:checked {
		background-color: #098350;
	}
	.allCheck{
		display:inline;
	}
	.allCheck:not(:first-child){
		margin-left: 5pt;
	}
	.bs-icon {
		--bs-icon-size: .75rem;
		display: flex;
		flex-shrink: 0;
		justify-content: center;
		align-items: center;
		font-size: var(--bs-icon-size);
		width: calc(var(--bs-icon-size) * 2);
		height: calc(var(--bs-icon-size) * 2);
		color: var(--bs-primary);
	  }
	  
	  .bs-icon-xs {
		--bs-icon-size: 1rem;
		width: calc(var(--bs-icon-size) * 1.5);
		height: calc(var(--bs-icon-size) * 1.5);
	  }
	  
	  .bs-icon-sm {
		--bs-icon-size: 1rem;
	  }
	  
	  .bs-icon-md {
		--bs-icon-size: 1.5rem;
	  }
	  
	  .bs-icon-lg {
		--bs-icon-size: 2rem;
	  }
	  
	  .bs-icon-xl {
		--bs-icon-size: 2.5rem;
	  }
	  
	  .bs-icon.bs-icon-primary {
		color: var(--bs-white);
		background: var(--bs-primary);
	  }
	  
	  .bs-icon.bs-icon-primary-light {
		color: var(--bs-primary);
		background: rgba(var(--bs-primary-rgb), .2);
	  }
	  
	  .bs-icon.bs-icon-semi-white {
		color: var(--bs-primary);
		background: rgba(255, 255, 255, .5);
	  }
	  
	  .bs-icon.bs-icon-rounded {
		border-radius: .5rem;
	  }
	  
	  .bs-icon.bs-icon-circle {
		border-radius: 50%;
	  }

	  .info {
		color: #09854e;
		/*font-size: 10pt;*/
		margin: 0.2em 0.2em 0.2em 0.2em;
		position: absolute;
		padding: 0.1em;
		border-radius: 1em;
		/*width: 15pt;*/
		text-align: center;
		border: #09854e solid 1px;
	  }
	  .category {
		display: grid;
	  }
	  .accordion-collapse {
		.category{
			@media (max-width: 1920px) {
				grid-template-columns: repeat(auto-fill,minmax(50%, 1fr));
			}
			@media (max-width: 800px) {
				grid-template-columns: repeat(auto-fill,minmax(100%, 1fr));
			}
			@media (min-width: 1921px) {
				grid-template-columns: repeat(auto-fill,minmax(33.33%, 1fr));
			}
		}

	  }
`;