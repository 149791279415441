/* eslint-disable no-param-reassign */
import { alerts } from 'helpers/api';
import { getUserInfo } from 'helpers/api/user';
import {
	CatCount,
	ResetBtn,
	ResetPromptBtn,
	ResetPromptSpan,
	Wrapper,
} from 'pages/alert-management/styled';
import { useDashboard } from 'pages/dashboard/DashboardContext';
import { useEffect, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import toast from 'react-hot-toast';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { useQuery } from 'react-query';
import AlertEquipmentListPopup from './alerts/AlertEquipmentListPopup';

let category = '';
export default function Alerts() {
	const [formData, setFormData] = useState([]);
	const { equipments, timezones, filteredResult } = useDashboard();
	const {
		data,
		isLoading,
		refetch: refetchAlerts,
	} = useQuery('alerts', alerts.getUserAlerts);
	const {
		data: listData,
		isLoading: listLoading,
		refetch: refetchAlertsList,
	} = useQuery('alertsList', alerts.getAlertList);
	const [eqModal, setEqModal] = useState(false);
	const [eqCat, setEqCat] = useState(false);
	const [loading, setLoading] = useState(false);
	let intromsg = `No notification active. Please go to the user profile to enable notifications`;
	let notice = '';
	let isBlocked = false;

	const verifyChanges = () => {
		let changes = '';
		const changesMade =
			localStorage.getItem('userAlerts') !==
			localStorage.getItem('resetAlerts');
		if (changesMade) {
			changes = `Unsaved changes`;
		}
		document.querySelector('#changes').innerHTML = changes;
		return changesMade;
	};

	const handleOpenEqModal = () => setEqModal(true);
	const handleCloseEqModal = () => {
		setEqModal(false);
		verifyChanges();
	};
	const handleEqCat = (e) => setEqCat(e);

	const selectEquipment = (serial, cat, selected) => {
		// If e is a serial get the correct values
		try {
			// Check if it was selected or not
			const dta = formData;
			// Add alert to alertLisdt for equipment
			// Find or add equipment
			const foundIndex = dta.findIndex((el) => serial === el.serial);
			if (foundIndex === -1) {
				// Add equipment
				dta.push({
					serial,
					alertList: [
						{ name: cat, sms: selected, email: selected, push: selected },
					],
				});
			} else {
				// Update equipment
				const aLIndex = dta[foundIndex].alertList.findIndex(
					(al) => al.name === cat,
				);
				if (aLIndex === -1) {
					// Add category
					dta[foundIndex].alertList.push({
						name: cat,
						sms: selected,
						email: selected,
						push: selected,
					});
				} else {
					dta[foundIndex].alertList[aLIndex] = {
						name: cat,
						sms: selected,
						email: selected,
						push: selected,
					};
				}
			}
			localStorage.setItem('userAlerts', JSON.stringify(dta));
		} catch (e) {
			throw new Error(`Unable to select equipment: ${e.message}`);
		}
		return true;
	};
	const selectAllEquipment = (e) => {
		const cat = e.target.dataset.category;
		equipments.forEach((eq) => {
			selectEquipment(eq.equip_serial, cat, e.target.checked);
		});
		verifyChanges();
	};

	const { data: usersData } = useQuery('users', () => getUserInfo());
	const udata = usersData?.data?.profile;
	useEffect(() => {
		if (data?.data?.userAlerts) {
			setFormData(data?.data?.userAlerts);
			localStorage.setItem(
				'userAlerts',
				JSON.stringify(data?.data?.userAlerts),
			);
			localStorage.setItem(
				'resetAlerts',
				JSON.stringify(data?.data?.userAlerts),
			);
		}
	}, [data?.data?.userAlerts]);

	const openModal = (iN) => {
		category = iN.target.dataset;
		handleOpenEqModal();
		handleEqCat(category);
	};

	function addCategory(tags, listItem) {
		const found = tags.find((el) => el === listItem.tags);
		const catNameCount = document.querySelector(`#catcount_${tags[0]}`);
		if (found) {
			const itemName = listItem.name;
			const itemTitle = listItem.description;
			if (catNameCount) {
				const catcount = catNameCount
					.closest('.accordion-header')
					.nextElementSibling.children[0].querySelectorAll('.accordion').length;
				catNameCount.innerHTML =
					catcount !== 1 ? `${catcount} items` : `${catcount} item`;
			}
			return (
				<Accordion key={itemName} className="">
					<Accordion.Item eventKey={itemName} key={itemName} className="py-2">
						<div className="px-1.5 py-1 rounded-lg duration-300">
							<Form.Check.Input
								type="checkbox"
								className="hidden sr-only check selectAllEquipment"
								disabled={isBlocked}
								onChange={selectAllEquipment}
								data-category={itemName}
							/>
							<Form.Check.Label
								className="m-0 font-semibold duration-200 ms-1 vertical-align-middle hover:font-bold"
								onClick={openModal}
								data-name={itemName}
								data-desc={itemTitle}
							>
								{itemTitle}
							</Form.Check.Label>
						</div>
					</Accordion.Item>
				</Accordion>
			);
		}
		return '';
	}

	const handleSubmit = () => {
		if (verifyChanges() === false) {
			toast.dismiss();
			toast((t) => <span>No Unsaved Changes</span>, {
				duration: 2000,
				position: 'top-right',
			});
			return;
		}
		setLoading(true);
		const request = alerts.updateUserAlerts(
			JSON.parse(localStorage.getItem('userAlerts')),
		);
		toast.promise(request, {
			loading: 'Updating alerts...',
			success: 'Alerts updated successfully',
			error: 'Failed to update alerts',
		});
		request
			.then((res) => {
				if (res.data.saveAlert.success) {
					// Clear message after success response.
					document.querySelector('#changes').innerHTML = '';
					refetchAlerts();
					refetchAlertsList();
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};
	const resetFormData = (id, res) => {
		toast.dismiss(id);
		const resetmsg = res ? 'Reset Complete' : 'Canceled';
		if (res) {
			document.querySelectorAll('.selectAllEquipment').forEach((el) => {
				el.checked = false;
			});
			localStorage.setItem('userAlerts', localStorage.getItem('resetAlerts'));
			verifyChanges();
		}
		toast(
			(t) => (
				<span>
					{resetmsg}{' '}
					<button
						type="button"
						onClick={() => toast.dismiss(t.id)}
						className="mt-2"
					>
						<AiOutlineCloseCircle className="text-red-500" />
					</button>
				</span>
			),
			{
				duration: 2000,
				position: 'top-center',
			},
		);
	};
	const handleReset = () => {
		toast.dismiss();
		if (!verifyChanges()) {
			toast(() => <span>No Unsaved Changes</span>, {
				duration: 2000,
				position: 'top-right',
			});
			return;
		}
		toast.loading((t) => (
			<ResetPromptSpan>
				Do you want to reset your selection?
				<br />
				<ResetPromptBtn
					className="rst_cancel"
					onClick={() => resetFormData(t.id)}
				>
					Cancel
				</ResetPromptBtn>
				<ResetPromptBtn
					className="mr-2 rst_continue"
					onClick={() => resetFormData(t.id, true)}
				>
					Yes, continue
				</ResetPromptBtn>
			</ResetPromptSpan>
		));
	};

	if (isLoading || listLoading) {
		return (
			<div className="d-grid justify-items-center h-90 align-content-center">
				<Spinner animation="border" size="md" />
			</div>
		);
	}

	if (!equipments || equipments?.length === 0 || !udata) {
		return (
			<Wrapper className="h-90 d-grid justify-items-center align-content-center">
				<Spinner size="lg" animation="border" />
			</Wrapper>
		);
	}
	const groupData = [
		// { name: 'Engine', description: '', tags: ['eng'] },
		{ name: 'Draw Works', description: '', tags: ['dw'] },
		// { name: 'Swivel', description: '', tags: ['sw'] },
		// {
		// 	name: 'BOP/Rod Handler/Interlock',
		// 	description: '',
		// 	tags: ['bop', 'rod'],
		// },
		// { name: 'Weather', description: '', tags: ['wth'] },
		// { name: 'Miscellaneous', description: '', tags: ['misc'] },
	];

	try {
		if (JSON.parse(udata?.notifications)) {
			const aType = JSON.parse(udata?.notifications);
			intromsg = 'Get notified via email, SMS and push notifications.';

			if (aType.includes('push')) {
				const np = Notification.permission;
				if (np === 'default') {
					Notification.requestPermission().then((perm) => {
						if (perm === 'granted') {
							const msg = `Notifications activated for this device`;
							const noti = new Notification('Push Notification', {
								body: `${msg}`,
								icon: '/static/media/logo-minify.9b4bfbcf117da3539112.png',
								tag: 'accepted',
								renotify: true,
							});
						}
					});
				} else if (np === 'denied') {
					notice = `Push Notifications are disabled, please go to your browser's options and enable Push Notifications`;
				}
			}
		}
	} catch (e) {
		isBlocked = true;
	}
	return (
		<Wrapper>
			<h1>
				Alert notifications for: {udata?.name} {udata?.middlename}{' '}
				{udata?.lastname} ({udata?.username})
			</h1>
			<div className="content">
				<h2 className="mb-2">
					{intromsg}
					<br />
					{notice}
					<br />
					<span id="changes" />
					{/* Check of all events that you want to be notified about via SMS, Email
					or Both. */}
				</h2>
				{/* Main Accordion */}
				<div className="row gy-4 row-cols-1 row-cols-md-2 row-cols-xl-3">
					{groupData?.map?.((groupItem) => (
						<Accordion key={groupItem.name}>
							<Accordion.Item
								eventKey={groupItem.name}
								key={groupItem.name}
								className="mt-3"
							>
								<Accordion.Header className="">
									<h3>
										{groupItem.name}{' '}
										<CatCount id={`catcount_${groupItem.tags[0]}`}>
											No item
										</CatCount>
									</h3>
								</Accordion.Header>
								<Accordion.Body className="p-0 equipment category">
									{listData?.data?.alertFunctions?.map?.((listItem) =>
										addCategory(groupItem.tags, listItem),
									)}
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					))}
				</div>

				<div className="flex items-center gap-2 my-2 text-end justify-evenly md:justify-end">
					<Button
						variant="primary-green"
						className="mt-3"
						onClick={handleSubmit}
						disabled={loading}
					>
						Save {loading === true && <Spinner size="sm" animation="border" />}
					</Button>
					<ResetBtn className="mt-3 btn reset_btn" onClick={handleReset}>
						Reset
					</ResetBtn>
				</div>
				<AlertEquipmentListPopup
					equipmentList={filteredResult}
					timezones={timezones}
					saved={data?.data?.userAlerts}
					show={eqModal}
					category={eqCat}
					handleOpen={handleOpenEqModal}
					handleClose={handleCloseEqModal}
				/>
			</div>
		</Wrapper>
	);
}
export function categoryValue() {
	return category;
}
