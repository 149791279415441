import RigLayout from 'components/Layouts/RigLayout';
import NoData from 'components/NoData';
import { getCameraFeed } from 'helpers/api/equipments';
import { useRigDetail } from 'helpers/contexts/RigDetailProvider';
import CameraDetail, { getStatus } from 'pages/details/rig/camera/CameraDetail';
import CamView from 'pages/details/rig/camera/CamView';
import { CVContainer } from 'pages/details/styled';
import { useEffect, useState } from 'react';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

export default function CameraTab() {
	const { id } = useParams();
	const [showPopup, setShowPopup] = useState(false);
	const [fullColumn, setFullColumn] = useState(6);
	const [camCSSClass, setCamCSSClass] = useState('camera-feed');
	const { data, isLoading }: any = useRigDetail();

	const cameraFeed = useQuery(`cameraView-${id}`, () => getCameraFeed(id), {
		enabled: !!id,
		refetchInterval: 5000,
		refetchIntervalInBackground: true,
		refetchOnWindowFocus: false,
	});

	const handleQuickCamera = () => setShowPopup(!showPopup);

	const handleFullColumn = (size, css) => {
		setFullColumn(size);
		setCamCSSClass(css);
	};

	useEffect(() => {
		handleFullColumn(fullColumn, camCSSClass);
	}, [fullColumn]);

	if (!cameraFeed) return <Spinner animation="border" className="mt-4" />;

	if (typeof cameraFeed?.data?.error !== 'undefined')
		return (
			<RigLayout>
				<NoData />
			</RigLayout>
		);

	useEffect(() => {
		if (data && data.serial) {
			let v = 192;
			const el: any = document.querySelector(`.posiInfo.${data.serial}`);
			if (el) {
				const spd: any = document.querySelector(`.speed.${data.serial}`);
				if (spd) {
					if (data.vehicle.status !== 'Stationary') {
						spd.style.display = 'block';
						spd.innerHTML = `${data.vehicle.speed} MPH`;
					} else {
						spd.style.display = 'none';
					}
				}
				if (getStatus(data)) {
					v = data?.positionPercent * 2 || 0;
					if (v > 192) v = 192;
					if (v < 8) v = 0;
					v *= -1;
					const pos = `transform: translateY(${v}px)`;
					// el.querySelector(`#level`).setAttribute('y', v);
					const st = `fill: rgb(180, 33, 35); fill-opacity: 1; stroke: rgb(0, 0, 0); stroke-width: 0; stroke-dasharray: none; stroke-opacity: 1;${pos};`;
					el.querySelector(`.posiInfo.${data.serial} .level`).setAttribute(
						'style',
						st,
					);
					// if (data.active) {
					// 	el.querySelector(`.rpm`).innerText = data?.speedFeetPerMinute;
					// 	el.querySelector(`.weight`).innerText = data?.weight_on_hook;
					// } else {
					// 	el.querySelector(`.rpm`).innerText = '--';
					// 	el.querySelector(`.weight`).innerText = '--';
					// }
				} else {
					// el.querySelector(`#level`).setAttribute('y', v);
					// el.querySelector(`.rpm`).innerText = '--';
					// el.querySelector(`.weight`).innerText = '--';
				}
			}
		}
	}, [data]);

	return (
		<RigLayout>
			{showPopup && (
				<CamView data={cameraFeed} rigData={data} action={handleQuickCamera} />
			)}
			<CVContainer>
				{isLoading === true && (
					<Button
						disabled
						size="lg"
						variant="outline-success"
						style={{ color: '#00834d' }}
					>
						<Spinner
							as="span"
							animation="grow"
							size="sm"
							role="status"
							aria-hidden="true"
						/>{' '}
						Getting camera(s), please wait...
					</Button>
				)}
				{isLoading === false && (
					<div>
						{/* It works when cameraData API works well. */}
						{cameraFeed?.data !== undefined &&
						cameraFeed?.data?.data?.cameraData.length > 0 ? (
							<Row className="camera-feed-container">
								{Object.entries(cameraFeed?.data?.data ?? {}).map(
									([key, value]: [string, any], index) => {
										const status = getStatus(data);
										return value?.map((camera, j) => (
											<Col
												lg={fullColumn}
												className={camCSSClass}
												onClick={handleQuickCamera}
											>
												<img src={camera.url} alt="" title="camera-feed" />
												{j === 0 && index === 0 && (
													<CameraDetail
														item={[key, value]}
														status={status}
														id={id}
														data={data}
													/>
												)}
											</Col>
										));
									},
								)}
							</Row>
						) : (
							<Row className="camera-feed-container">
								{/* It works when some problem with cameraData API failed. */}
								{data?.status?.camera?.data !== null ? (
									Object.entries(data?.status?.camera?.data ?? {}).map(
										([key, value]: [string, any], index) => {
											const status = getStatus(data);
											return value?.map((camera, j) => (
												<Col
													lg={fullColumn}
													className={camCSSClass}
													onClick={handleQuickCamera}
												>
													<img src={camera.path} alt="" title="camera-feed" />
													{j === 0 && index === 0 && (
														<CameraDetail
															item={[key, value]}
															status={status}
															id={id}
															data={data}
														/>
													)}
												</Col>
											));
										},
									)
								) : (
									<>No cameras found</>
								)}
							</Row>
						)}
					</div>
				)}
			</CVContainer>
		</RigLayout>
	);
}
