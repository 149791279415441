import { useState } from 'react';
import { Nav, Row } from 'react-bootstrap';
import { AiOutlineCloseCircle } from 'react-icons/ai';

import { Rnd } from 'react-rnd';
import styled from 'styled-components';
import { CVContainer, CloseButton } from './styles';

const NavWrapper = styled(Row)`
	.cam-nav-view {
		width: 95%;
		margin: 1.3rem;
		margin-bottom: 0 !important;
		padding-bottom: 0 !important;
		.nav-item.active {
			border-bottom: 2px solid ${({ theme }) => theme.colors.borderHightLight};
			padding-bottom: 0.75rem;
		}
	}
`;

function CamView(props) {
	const { data, action, rigData } = props;
	const [currentCameraIndex, setCurrentCameraIndex] = useState(0);

	let cameraList = !data?.data?.error ? data?.data?.data?.cameraData : [];

	if (
		(cameraList === undefined || cameraList.length === 0) &&
		rigData &&
		rigData.serial &&
		rigData.status.camera &&
		rigData.status.camera.data
	) {
		cameraList = Object.values(rigData.status.camera.data)?.flatMap(
			(cam: any) => {
				if (cam.length > 0) {
					return [...cam];
				}
				return cam;
			},
		);
	}

	if (cameraList.length === 0) return null;
	return (
		<Rnd
			default={{
				width: '80%',
				height: 'auto',
				x: 100,
				y: 100,
			}}
			style={{
				zIndex: 999,
				width: 'auto',
				height: 'auto',
				contain: 'paint',
			}}
			className="shadow-2xl border-2 border-gray-300 rounded-xl "
			bounds="window"
		>
			<CVContainer className="text-end relative ">
				<CloseButton onClick={action} className="absolute top-5 right-7">
					<AiOutlineCloseCircle className="cursor-pointer w-7 h-7 text-danger" />
				</CloseButton>
				<NavWrapper className="px-2">
					<Nav className="cam-nav-view ">
						{cameraList?.map((item, index) => (
							<Nav.Item
								role="button"
								key={item.camera_id}
								className={`nav-item px-2 font-semibold ${
									currentCameraIndex === index ? 'active' : ''
								}`}
								onClick={() => setCurrentCameraIndex(index)}
							>
								Camera {index + 1}
							</Nav.Item>
						))}
					</Nav>
				</NavWrapper>

				<Row className="camera-feed-container">
					<div
						key={`Camera-feed-${currentCameraIndex}`}
						className="camera-feed mx-3 mb-3"
					>
						<img
							src={
								cameraList?.[currentCameraIndex]?.url ||
								cameraList?.[currentCameraIndex]?.path
							}
							alt=""
							title="camera-feed"
						/>
					</div>
				</Row>
			</CVContainer>
		</Rnd>
	);
}

export default CamView;
