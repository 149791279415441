import { ReactComponent as EquipmentIcon } from 'assets/icons/equipment.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/location.svg';
import { ReactComponent as ReportIcon } from 'assets/icons/report.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/video.svg';
import { status } from 'components/EquipmentHandler';
import RigTemperatureConverter from 'components/Unit/RigTemperatureConverter';
import RigSpeedConverter from 'components/Unit/RigSpeedConverter';
import TooltipHandler from 'components/TooltipHandler';
import { getCameraFeed } from 'helpers/api/equipments';
import { useAuth } from 'helpers/contexts/AuthContext';
import { useRigDetail } from 'helpers/contexts/RigDetailProvider';
import useResponsive from 'helpers/hooks/useResponsive';
import moment from 'moment-timezone';
import CamView from 'pages/details/rig/camera/CamView';
import ReportsForm from 'pages/reports/ReportsForm';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import ProfilePopup from './ProfilePopup';

function Details() {
	const { id } = useParams();
	const { isTablet, isMobile } = useResponsive();
	const [readMore, setReadMore] = useState(true);
	const [showPopup, setShowPopup] = useState(false);
	const [editProfileModal, setEditProfileModal] = useState(false);
	const [showReportModal, setShowReportModal] = useState(false);
	const { data, refetch } = useRigDetail();
	const { user } = useAuth();

	const cameraFeed = useQuery(`cameraView-${id}`, () => getCameraFeed(id), {
		enabled: !!id,
		refetchInterval: 5000,
		refetchIntervalInBackground: true,
		refetchOnWindowFocus: false,
	});

	useEffect(() => {
		const interval = setInterval(() => {
			const date = moment()?.format('Do MMM, YYYY');
			const time = moment()
				?.tz(data?.timezoneIdentifier || 'America/Chicago')
				?.format('hh:mm:ss a');
			document.getElementById('displayTime').textContent =
				`${date || ''} ${time || ''} `;
		});
		return () => clearInterval(interval);
	}, [data]);

	const handleQuickCamera = () => setShowPopup(!showPopup);
	const handleReadMore = () => setReadMore(!readMore);
	const handleOpenShowReportModal = () => setShowReportModal(true);
	const handleCloseShowReportModal = () => setShowReportModal(false);
	const toggleEditProfileModal = (open = false) => setEditProfileModal(open);

	const openTravelToSite = () =>
		window.open(
			`https://www.google.com/maps/dir/?api=1&destination=${data?.latitude},${data?.longitude}`,
			'_blank',
		);

	return (
		<>
			{showPopup && (
				<CamView data={cameraFeed} rigData={data} action={handleQuickCamera} />
			)}
			<ProfilePopup
				key={`${id}_profile`}
				data={data}
				open={editProfileModal}
				onClose={toggleEditProfileModal}
				refetch={refetch}
			/>
			<ReportsForm
				show={showReportModal}
				handleClose={handleCloseShowReportModal}
				multi={false}
			/>
			<div className="mb-2">
				<div
					className={
						readMore ? 'details-information' : 'details-information extended'
					}
				>
					<div className="flex flex-wrap items-center gap-4">
						<span className="eq-id">
							RIG
							{': '}
							{id ?? '-'}
						</span>
						{status(data?.status)}

						{isMobile ? <br /> : null}
						<label className="date">
							<span id="displayTime" />
							<span className="tags">{data?.timezoneName ?? ''}</span>
						</label>

						<div className="flex gap-4 ml-auto">
							<TooltipHandler
								content={<h6 className="mb-0 fw-normal">View Camera</h6>}
								hide={data?.status?.camera?.data === null}
							>
								<button
									type="button"
									onClick={handleQuickCamera}
									className="disabled:text-gray-500 disabled:cursor-not-allowed"
									disabled={
										data === null ? true : data?.status?.camera?.data === null
									}
									// style={{display: (data === null ?  "none" :  data?.status?.camera?.data === null) ? "none" : ""}}
									style={{ display: user.access.includes('cam') ? '' : 'none' }}
								>
									<VideoIcon
										style={{ width: '1.25rem' }}
										className="stroke-current"
									/>
								</button>
							</TooltipHandler>

							<TooltipHandler
								content={<h6 className="mb-0 fw-normal">Show Location</h6>}
							>
								<span
									title="Rig Location"
									style={{ cursor: 'pointer' }}
									onClick={openTravelToSite}
								>
									<LocationIcon style={{ width: '1.25rem' }} />
								</span>
							</TooltipHandler>
							<TooltipHandler
								content={<h6 className="mb-0 fw-normal">Equipment Profile</h6>}
							>
								<span
									title="Equipment Profile"
									style={{ cursor: 'pointer' }}
									onClick={() => toggleEditProfileModal(true)}
								>
									<EquipmentIcon style={{ width: '1.25rem' }} />
								</span>
							</TooltipHandler>
							<TooltipHandler
								content={<h6 className="mb-0 fw-normal">Equipment Report</h6>}
							>
								<span
									title="Equipment Report"
									style={{ cursor: 'pointer' }}
									onClick={handleOpenShowReportModal}
								>
									<ReportIcon style={{ width: '1.25rem' }} />
								</span>
							</TooltipHandler>
						</div>
						{isMobile || isTablet ? <br /> : null}
						<button
							type="button"
							className={readMore ? 'bottom-right' : 'bottom-right-extended'}
							onClick={handleReadMore}
						>
							View {readMore ? 'More Details' : 'Less Details'}
						</button>
					</div>

					{readMore ? null : (
						<div className="more-info">
							<Row>
								<Col lg={2} className="tags">
									<span>Yard Number</span>
									<span className="value">{data?.yardNumber ?? '-'}</span>
								</Col>
								<Col lg={2} className="tags">
									<span>Operator</span>
									<span className="value">{data?.operator ?? '-'}</span>
								</Col>
								<Col lg={2} className="tags">
									<span>Last Calibration Time</span>
									<span className="value">
										{data?.status?.calibration?.date ?? '-'}
									</span>
								</Col>
								<Col lg={2} className="tags">
									<span>Encoder</span>
									<span className="value">
										{/* {isNaN(data?.status?.calibration?.count)
											? '-'
											: Math.abs(data?.status?.calibration?.count)} */}
										{Math.abs(data?.positionCalibrationCount || 0)}
									</span>
								</Col>
								<Col lg={2} className="tags">
									<span>Supervisor</span>
									<span className="value">{data?.supervisor ?? '-'}</span>
								</Col>
								<Col lg={2} className="tags">
									<span>Data System Voltage</span>
									<span className="value">{data?.powerVoltage ?? '-'}v</span>
								</Col>
								<Col lg={2} className="tags">
									<span>Yard Name</span>
									<span className="value">{data?.yardName}</span>
								</Col>
								<Col lg={2} className="tags">
									<span>Camera ID</span>
									<span className="value">{data?.camId ?? '-'}</span>
								</Col>
								<Col lg={2} className="tags">
									<span>Camera Software</span>
									<span className="value">{data?.cameraSoftware ?? '-'}</span>
								</Col>
								<Col lg={2} className="tags">
									<span>X Value</span>
									<span className="value">
										{data?.status?.status?.flag ?? '-'}
									</span>
								</Col>
								<Col lg={2} className="tags">
									Rig Speed:{' '}
									<span className="value">
										<RigSpeedConverter
											rigSpeed={data?.status?.vehicle?.speed}
											rigUnit={data?.status?.vehicle?.unit}
											userUnit={user.speedUnit}
										/>
										{/* {Math.round(parseFloat(data?.vehicleSpeed)) ?? '-'} MPH */}
									</span>
								</Col>
								<Col lg={2} className="tags">
									Engine:{' '}
									<span className="value">
										{data?.status?.engine === true ? 'On' : 'Off'}
									</span>
								</Col>
							</Row>
							<Row>
								<Col lg={2} className="tags">
									Wind Direction:{' '}
									<span className="value">
										{data?.status?.weather?.wind?.direction?.full || '-'}
									</span>
								</Col>
								<Col lg={2} className="tags">
									Wind Speed:{' '}
									<span className="value">
										<RigSpeedConverter
											rigSpeed={data?.status?.weather?.wind?.speed}
											rigUnit={data?.status?.weather?.wind?.unit}
											userUnit={user.speedUnit}
										/>
									</span>
								</Col>
								<Col lg={2} className="tags">
									Temperature:{' '}
									<span className="value">
										<RigTemperatureConverter
											rigTemp={data?.status?.weather?.temp?.value}
											rigUnit={data?.status?.weather?.temp?.unit}
											userUnit={user.temperatureUnit}
										/>
									</span>
								</Col>
							</Row>
						</div>
					)}
				</div>
			</div>
		</>
	);
}

export default Details;
